import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

const { API_BASE_URL } = config;  // Replace hardcoded URL

const PublicVarieties = () => {
    const [varieties, setVarieties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchVarieties = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/varieties`);
                if (!response.ok) throw new Error('Failed to fetch varieties');
                const data = await response.json();
                setVarieties(data);
            } catch (error) {
                setError('Failed to load varieties');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVarieties();
    }, []);

    if (loading) return <div style={styles.loading}>Loading...</div>;
    if (error) return <div style={styles.error}>{error}</div>;

    return (
        <div style={styles.container}>
            <Link to="/" style={styles.backLink}>← Back to Calendar</Link>
            <h1 style={styles.heading}>Avocado Varieties</h1>
            <div style={styles.varietiesGrid}>
                {varieties.map(variety => (
                    <Link 
                        key={variety.id} 
                        to={`/variety/${variety.id}`}
                        style={styles.varietyCard}
                    >
                        <h2 style={styles.varietyName}>{variety.name}</h2>
                        <p style={styles.varietyDescription}>
                            {variety.description.length > 150 
                                ? `${variety.description.substring(0, 150)}...` 
                                : variety.description}
                        </p>
                        <p style={styles.varietyOrigin}>Origin: {variety.origin}</p>
                        <div style={styles.readMore}>Read More →</div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
    },
    heading: {
        color: '#2e7d32',
        textAlign: 'center',
        marginBottom: '30px',
        fontSize: '2.5em',
    },
    backLink: {
        display: 'inline-block',
        marginBottom: '20px',
        color: '#2e7d32',
        textDecoration: 'none',
        fontSize: '16px',
    },
    varietiesGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '20px',
        padding: '20px 0',
    },
    varietyCard: {
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        textDecoration: 'none',
        color: 'inherit',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
        },
    },
    varietyName: {
        color: '#2e7d32',
        marginBottom: '10px',
        fontSize: '1.5em',
    },
    varietyDescription: {
        color: '#666',
        marginBottom: '15px',
        lineHeight: '1.4',
    },
    varietyOrigin: {
        color: '#558b2f',
        fontStyle: 'italic',
    },
    readMore: {
        color: '#2e7d32',
        marginTop: '10px',
        fontWeight: 'bold',
    },
    loading: {
        textAlign: 'center',
        padding: '50px',
        fontSize: '1.2em',
        color: '#666',
    },
    error: {
        textAlign: 'center',
        padding: '50px',
        color: '#d32f2f',
    },
};

export default PublicVarieties; 