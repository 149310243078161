import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import AvocadoCalendar from './AvocadoCalendar';
import AdminPanel from './AdminPanel';
import AvocadoVarietyDetails from './components/AvocadoVarietyDetails';
import PublicVarieties from './components/PublicVarieties';
import UserProfile from './components/UserProfile';
import Login from './components/Login';
import Register from './components/Register';
import Navigation from './components/Navigation';

// Create a new component for the app content
const AppContent = () => {
    const { user, logout } = useAuth();

    return (
        <div style={styles.app}>
            <Navigation />
            <main style={styles.main}>
                <Routes>
                    <Route path="/" element={<AvocadoCalendar />} />
                    <Route path="/varieties" element={<PublicVarieties />} />
                    <Route path="/variety/:id" element={<AvocadoVarietyDetails />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route 
                        path="/admin" 
                        element={
                            user?.isAdmin ? <AdminPanel /> : <Navigate to="/" />
                        } 
                    />
                    <Route 
                        path="/profile" 
                        element={
                            user ? <UserProfile /> : <Navigate to="/login" />
                        }
                    />
                </Routes>
            </main>
        </div>
    );
};

// Main App component
function App() {
    return (
        <Router>
            <AuthProvider>
                <AppContent />
            </AuthProvider>
        </Router>
    );
}

const styles = {
    app: {
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    main: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '80px 20px 20px',
        '@media (max-width: 768px)': {
            padding: '70px 15px 15px',
        },
    },
    nav: {
        marginBottom: '20px',
    },
    logoutButton: {
        background: 'none',
        border: 'none',
        color: 'inherit',
        cursor: 'pointer',
        padding: 0,
    },
};

export default App;