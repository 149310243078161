import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import './styles/animations.css';
import config from './config';

// Replace the hardcoded API_BASE_URL with the config version
const { API_BASE_URL } = config;

const AvocadoCalendar = () => {
  const { user } = useAuth();
  const [calendarData, setCalendarData] = useState({});
  const [selectedRegion, setSelectedRegion] = useState('Southern California');
  const [showOnlyMyTrees, setShowOnlyMyTrees] = useState(false);
  
  const getCurrentMonthIndex = () => new Date().getMonth(); // 0-11

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const headers = {};
        if (user?.token) {
          headers.Authorization = `Bearer ${user.token}`;
        }

        const response = await fetch(
          `${API_BASE_URL}/calendar/${encodeURIComponent(selectedRegion)}`,
          { headers }
        );
        const harvestData = await response.json();

        const months = {
          1: 'January', 2: 'February', 3: 'March', 4: 'April',
          5: 'May', 6: 'June', 7: 'July', 8: 'August',
          9: 'September', 10: 'October', 11: 'November', 12: 'December'
        };

        const transformedData = {};
        for (const month of Object.values(months)) {
          transformedData[month] = {
            varieties: []
          };
        }

        // Filter data if showOnlyMyTrees is true
        const filteredHarvestData = showOnlyMyTrees
          ? harvestData.filter(record => record.is_user_variety)
          : harvestData;

        filteredHarvestData.forEach(record => {
          const monthName = months[record.month];
          transformedData[monthName].varieties.push({
            id: parseInt(record.variety_id),
            name: String(record.variety_name),
            isFruiting: Boolean(record.is_fruiting)
          });
        });

        setCalendarData(transformedData);
      } catch (error) {
        console.error('Error fetching calendar data:', error);
      }
    };

    fetchCalendarData();
  }, [selectedRegion, showOnlyMyTrees, user?.token]);

  const styles = {
    body: {
      fontFamily: "'Comic Sans MS', cursive, sans-serif",
      margin: 0,
      padding: '20px',
      backgroundColor: '#f0f7e6',
      color: '#3a3a3a',
    },
    calendarContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    headerContainer: {
      position: 'relative',
      paddingTop: '10px',
      marginBottom: '30px',
    },
    h1: {
      textAlign: 'center',
      color: '#2e7d32',
      marginBottom: '5px',
      fontSize: '36px',
      textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
    },
    subtitle: {
      textAlign: 'center',
      fontSize: '18px',
      color: '#558b2f',
      marginBottom: '20px',
    },
    headerAvocadoLeft: {
      position: 'absolute',
      left: '10%',
      top: '10px',
      fontSize: '60px',
      transform: 'rotate(-15deg)',
    },
    headerAvocadoRight: {
      position: 'absolute',
      right: '10%',
      top: '10px',
      fontSize: '60px',
      transform: 'rotate(15deg)',
    },
    avocadoQuote: {
      textAlign: 'center',
      fontStyle: 'italic',
      marginBottom: '40px',
      fontSize: '18px',
      color: '#558b2f',
    },
    monthGrid: {
      display: 'grid',
      gap: '20px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
      marginBottom: '30px',
    },
    month: {
      backgroundColor: 'white',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      transition: 'all 0.3s ease',
      border: '1px solid transparent',
    },
    monthHeader: {
      backgroundColor: '#558b2f',
      color: 'white',
      padding: '12px 15px',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'all 0.3s ease',
    },
    monthContent: {
      padding: '15px',
      minHeight: '100px',
    },
    varietyItem: {
      margin: '5px 0',
      padding: '8px 10px',
      borderRadius: '8px',
      fontSize: '15px',
      backgroundColor: '#a5d6a7',
      fontWeight: 'bold',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      borderLeft: '5px solid #2e7d32',
    },
    varietyLink: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      cursor: 'pointer',
    },
    varietiesLink: {
      display: 'inline-block',
      color: '#2e7d32',
      textDecoration: 'none',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    welcomeCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      padding: '2rem',
      marginBottom: '2rem',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      textAlign: 'center',
    },
    welcomeTitle: {
      color: '#2e7d32',
      fontSize: '2rem',
      marginBottom: '1rem',
    },
    welcomeText: {
      fontSize: '1.2rem',
      color: '#333',
      marginBottom: '1.5rem',
    },
    featureList: {
      listStyle: 'none',
      padding: '0',
      marginBottom: '2rem',
      fontSize: '1.1rem',
      display: 'grid',
      gap: '1rem',
      '@media (min-width: 768px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
    ctaContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      '@media (min-width: 768px)': {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    ctaButton: {
      backgroundColor: '#2e7d32',
      color: 'white',
      padding: '1rem 2rem',
      borderRadius: '8px',
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#1b5e20',
      },
    },
    secondaryButton: {
      backgroundColor: '#e8f5e9',
      color: '#2e7d32',
      padding: '1rem 2rem',
      borderRadius: '8px',
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#c8e6c9',
      },
    },
    userDashboard: {
      marginBottom: '2rem',
    },
    dashboardTitle: {
      color: '#2e7d32',
      fontSize: '2rem',
      marginBottom: '1rem',
    },
    emptyState: {
      textAlign: 'center',
      marginBottom: '1rem',
    },
    addTreeButton: {
      backgroundColor: '#2e7d32',
      color: 'white',
      padding: '1rem 2rem',
      borderRadius: '8px',
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#1b5e20',
      },
    },
    toolbarSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginBottom: '30px',
      '@media (min-width: 768px)': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    ctaCard: {
      backgroundColor: '#e8f5e9',
      padding: '15px 20px',
      borderRadius: '8px',
      textAlign: 'center',
      '@media (min-width: 768px)': {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
      },
    },
    ctaText: {
      color: '#2e7d32',
      marginBottom: '10px',
      '@media (min-width: 768px)': {
        marginBottom: 0,
      },
    },
    ctaButtons: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    },
    infoSection: {
      textAlign: 'center',
      marginTop: '30px',
    },
    toolbarLeft: {
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
      flexWrap: 'wrap',
      '@media (max-width: 768px)': {
        width: '100%',
        justifyContent: 'center',
      },
    },
    filterButton: {
      padding: '12px 20px',
      borderRadius: '8px',
      border: '2px solid #2e7d32',
      backgroundColor: 'white',
      color: '#2e7d32',
      cursor: 'pointer',
      fontSize: '1rem',
      transition: 'all 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '&:hover': {
        backgroundColor: '#e8f5e9',
      },
      '@media (max-width: 768px)': {
        width: '100%',
        justifyContent: 'center',
      },
    },
    filterButtonActive: {
      backgroundColor: '#2e7d32',
      color: 'white',
      '&:hover': {
        backgroundColor: '#1b5e20',
      },
    },
    regionSelector: {
      padding: '12px',
      borderRadius: '8px',
      border: '2px solid #2e7d32',
      fontSize: '1rem',
      backgroundColor: 'white',
      color: '#2e7d32',
      cursor: 'pointer',
      minWidth: '200px',
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
    legend: {
      backgroundColor: '#e8f5e9',
      padding: '12px 20px',
      borderRadius: '8px',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#2e7d32',
      fontSize: '0.9rem',
      maxWidth: 'fit-content'
    },
    currentMonth: {
      transform: 'scale(1.02)',
      boxShadow: '0 4px 12px rgba(46,125,50,0.3)',
      border: '2px solid #2e7d32',
      backgroundColor: '#fcfff9',
    },
    currentMonthHeader: {
      backgroundColor: '#1b5e20',
      fontSize: '1.3rem',
      padding: '15px',
    },
    upcomingMonth: {
      transform: 'scale(1.01)',
      boxShadow: '0 2px 8px rgba(46,125,50,0.1)',
      border: '1px solid #a5d6a7',
    },
    upcomingMonthHeader: {
      backgroundColor: '#43a047',
    },
    fruitingEmoji: {
      animation: 'blink 2s ease-in-out infinite',
      display: 'inline-block',
    },
    '@keyframes blink': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0.3 },
      '100%': { opacity: 1 }
    },
  };

  const renderVarieties = (varieties) => {
    if (!varieties || varieties.length === 0) return null;
    
    return varieties.map((variety) => (
      <div 
        key={`variety-${variety.id}`}
        style={styles.varietyItem}
      >
        {showOnlyMyTrees && variety.isFruiting && (
          <span 
            role="img" 
            aria-label="fruiting" 
            className="fruiting-emoji"
          >
            🥑 
          </span>
        )}
        <Link 
          to={`/variety/${variety.id}`} 
          style={styles.varietyLink}
        >
          {variety.name}
        </Link>
      </div>
    ));
  };

  const renderMonths = () => {
    const currentMonthIndex = getCurrentMonthIndex();
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return (
      <div style={styles.monthGrid}>
        {months.map((month, index) => {
          const monthData = calendarData[month] || { varieties: [] };
          const isCurrentMonth = index === currentMonthIndex;
          const isUpcoming = index > currentMonthIndex && index <= currentMonthIndex + 2;

          return (
            <div 
              key={month} 
              style={{
                ...styles.month,
                ...(isCurrentMonth ? styles.currentMonth : {}),
                ...(isUpcoming ? styles.upcomingMonth : {})
              }}
            >
              <div style={{
                ...styles.monthHeader,
                ...(isCurrentMonth ? styles.currentMonthHeader : {}),
                ...(isUpcoming ? styles.upcomingMonthHeader : {})
              }}>
                {month}
              </div>
              <div style={styles.monthContent}>
                {renderVarieties(monthData.varieties)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={styles.body}>
      <div style={styles.calendarContainer}>
        <div style={styles.headerContainer}>
          <h1 style={styles.h1}>Avocado Harvest Calendar</h1>
          <div style={styles.subtitle}>
            Find the perfect time to harvest your avocados
          </div>
        </div>

        <div style={styles.toolbarSection}>
          <div style={styles.toolbarLeft}>
            <select 
              value={selectedRegion}
              onChange={(e) => setSelectedRegion(e.target.value)}
              style={styles.regionSelector}
            >
              <option value="Southern California">Southern California</option>
              <option value="Northern California">Northern California</option>
              <option value="Florida">Florida</option>
            </select>

            {user && (
              <button
                onClick={() => setShowOnlyMyTrees(!showOnlyMyTrees)}
                style={{
                  ...styles.filterButton,
                  ...(showOnlyMyTrees ? styles.filterButtonActive : {})
                }}
              >
                {showOnlyMyTrees ? '🌳 Showing My Trees' : '🌳 Show My Trees'}
              </button>
            )}
          </div>

          {!user && (
            <div style={styles.ctaCard}>
              <p style={styles.ctaText}>
                Own an avocado tree? Create your personalized calendar!
              </p>
              <div style={styles.ctaButtons}>
                <Link to="/register" style={styles.ctaButton}>
                  Get Started
                </Link>
                <Link to="/login" style={styles.secondaryButton}>
                  Login
                </Link>
              </div>
            </div>
          )}
        </div>

        {user && showOnlyMyTrees && (
          <div style={styles.legend}>
            <span role="img" aria-label="fruiting avocado">🥑</span>
            <span>indicates currently fruiting trees</span>
          </div>
        )}

        {renderMonths()}

        <div style={styles.infoSection}>
          <Link to="/varieties" style={styles.varietiesLink}>
            View All Avocado Varieties →
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AvocadoCalendar;