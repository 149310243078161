import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

const { API_BASE_URL } = config;

const UserProfile = () => {
    const [profile, setProfile] = useState(null);
    const [allVarieties, setAllVarieties] = useState([]);
    const [userVarieties, setUserVarieties] = useState([]);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isAllSelected, setIsAllSelected] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchProfile();
        fetchAllVarieties();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/user/profile`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            console.log('Profile data received:', data);
            setProfile(data);
            
            // Transform the selectedVarieties to ensure is_fruiting is properly set as a boolean
            const transformedVarieties = (data.selectedVarieties || []).map(variety => ({
                ...variety,
                is_fruiting: Boolean(variety.is_fruiting)  // Convert SQLite 0/1 to boolean
            }));
            
            setUserVarieties(transformedVarieties);
        } catch (error) {
            console.error('Error in fetchProfile:', error);
            setError('Failed to load profile');
        }
    };

    const fetchAllVarieties = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/varieties`);
            const data = await response.json();
            setAllVarieties(data);
        } catch (error) {
            setError('Failed to load varieties');
        }
    };

    const handleVarietySelect = async (variety) => {
        try {
            const isSelected = userVarieties.some(v => v.id === variety.id);
            let newUserVarieties;

            if (isSelected) {
                // Remove variety
                await fetch(`${API_BASE_URL}/user/varieties/${variety.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                newUserVarieties = userVarieties.filter(v => v.id !== variety.id);
            } else {
                // Add variety
                await fetch(`${API_BASE_URL}/user/varieties`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ varietyId: variety.id })
                });
                newUserVarieties = [...userVarieties, { ...variety, is_fruiting: false }];
            }

            setUserVarieties(newUserVarieties);
            setMessage(isSelected ? 'Variety removed' : 'Variety added');
            setTimeout(() => setMessage(''), 3000);
        } catch (error) {
            setError('Failed to update variety');
        }
    };

    const toggleFruiting = async (variety) => {
        try {
            const response = await fetch(`${API_BASE_URL}/user/varieties/${variety.id}/fruiting`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ is_fruiting: !variety.is_fruiting })
            });

            if (!response.ok) throw new Error('Failed to update fruiting status');

            setUserVarieties(userVarieties.map(v => 
                v.id === variety.id ? { ...v, is_fruiting: !v.is_fruiting } : v
            ));
        } catch (error) {
            setError('Failed to update fruiting status');
        }
    };

    const handleToggleAll = async () => {
        try {
            if (isAllSelected) {
                // Deselect all
                for (const variety of userVarieties) {
                    await fetch(`${API_BASE_URL}/user/varieties/${variety.id}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                }
                setUserVarieties([]);
                setIsAllSelected(false);
            } else {
                // Select all
                const newVarieties = [];
                for (const variety of allVarieties) {
                    if (!userVarieties.some(v => v.id === variety.id)) {
                        await fetch(`${API_BASE_URL}/user/varieties`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify({ varietyId: variety.id })
                        });
                        newVarieties.push({ ...variety, is_fruiting: false });
                    }
                }
                setUserVarieties([...userVarieties, ...newVarieties]);
                setIsAllSelected(true);
            }
            setMessage(isAllSelected ? 'All varieties deselected' : 'All varieties selected');
            setTimeout(() => setMessage(''), 3000);
        } catch (error) {
            setError('Failed to update varieties');
        }
    };

    if (!profile) return <div style={styles.loading}>Loading...</div>;

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>My Profile</h1>
            {message && <div style={styles.successMessage}>{message}</div>}
            {error && <div style={styles.errorMessage}>{error}</div>}
            
            <div style={styles.profileInfo}>
                <h2>Welcome, {profile.username}!</h2>
                <p>Email: {profile.email}</p>
            </div>

            <div style={styles.varietiesSection}>
                <h2>My Avocado Trees</h2>
                <p style={styles.instruction}>
                    Select the varieties you have and mark which ones are currently fruiting
                </p>
                
                <button 
                    onClick={handleToggleAll}
                    style={styles.toggleAllButton}
                >
                    {isAllSelected ? 'Deselect All Varieties' : 'Select All Varieties'}
                </button>

                <div style={styles.varietiesGrid}>
                    {allVarieties.map(variety => {
                        const userVariety = userVarieties.find(v => v.id === variety.id);
                        const isSelected = !!userVariety;

                        return (
                            <div key={variety.id} style={styles.varietyCard}>
                                <div style={styles.varietyHeader}>
                                    <label style={styles.checkboxLabel}>
                                        <input
                                            type="checkbox"
                                            checked={isSelected}
                                            onChange={() => handleVarietySelect(variety)}
                                            style={styles.checkbox}
                                        />
                                        <span style={styles.varietyName}>{variety.name}</span>
                                    </label>
                                </div>

                                {isSelected && (
                                    <div style={styles.fruitingSection}>
                                        <button
                                            onClick={() => toggleFruiting(userVariety)}
                                            style={{
                                                ...styles.fruitingButton,
                                                ...(userVariety.is_fruiting ? styles.fruitingActive : {})
                                            }}
                                        >
                                            {userVariety.is_fruiting ? '🥑 Currently Fruiting' : '🌱 Not Fruiting'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    heading: {
        color: '#2e7d32',
        marginBottom: '20px',
    },
    instruction: {
        color: '#666',
        marginBottom: '20px',
        fontSize: '1.1em',
    },
    successMessage: {
        backgroundColor: '#e8f5e9',
        color: '#2e7d32',
        padding: '10px',
        borderRadius: '4px',
        marginBottom: '20px',
    },
    errorMessage: {
        backgroundColor: '#ffebee',
        color: '#c62828',
        padding: '10px',
        borderRadius: '4px',
        marginBottom: '20px',
    },
    varietiesGrid: {
        display: 'grid',
        gap: '20px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
    varietyCard: {
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '15px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    varietyHeader: {
        marginBottom: '10px',
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
    },
    checkbox: {
        width: '18px',
        height: '18px',
    },
    varietyName: {
        fontSize: '1.1em',
        color: '#2e7d32',
    },
    fruitingSection: {
        marginTop: '10px',
        borderTop: '1px solid #eee',
        paddingTop: '10px',
    },
    fruitingButton: {
        width: '100%',
        padding: '8px',
        border: '2px solid #2e7d32',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: '#2e7d32',
        cursor: 'pointer',
        fontSize: '0.9em',
        transition: 'all 0.2s ease',
    },
    fruitingActive: {
        backgroundColor: '#2e7d32',
        color: 'white',
    },
    loading: {
        textAlign: 'center',
        padding: '50px',
        fontSize: '1.2em',
        color: '#666',
    },
    toggleAllButton: {
        backgroundColor: '#2e7d32',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1em',
        marginBottom: '20px',
        transition: 'background-color 0.2s'
    },
    toolbarLeft: {
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        flexWrap: 'wrap',
        '@media(minWidth: 768px)': {
            width: '100%',
            justifyContent: 'center',
        }
    },
};

export default UserProfile; 