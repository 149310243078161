import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import UserManagement from './components/UserManagement';
import config from './config';

const { API_BASE_URL } = config;

const AdminPanel = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginData, setLoginData] = useState({ username: '', password: '' });
    const [varieties, setVarieties] = useState([]);
    const [newVariety, setNewVariety] = useState({ name: '', description: '', origin: '' });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('varieties');

    const token = localStorage.getItem('adminToken');

    useEffect(() => {
        if (token) {
            setIsLoggedIn(true);
            fetchVarieties();
        }
    }, [token]);

    const fetchVarieties = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/admin/varieties`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch varieties');
            }
            const data = await response.json();
            setVarieties(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching varieties:', error);
            setError('Failed to fetch varieties');
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await fetch(`${API_BASE_URL}/auth/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginData)
            });
            
            if (!response.ok) {
                throw new Error('Invalid credentials');
            }
            
            const data = await response.json();
            if (data.token) {
                localStorage.setItem('adminToken', data.token);
                setIsLoggedIn(true);
                await fetchVarieties();
            } else {
                throw new Error('No token received');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError(error.message || 'Login failed');
        }
    };

    const handleAddVariety = async (e) => {
        e.preventDefault();
        try {
            await fetch(`${API_BASE_URL}/admin/varieties`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newVariety)
            });
            setNewVariety({ name: '', description: '', origin: '' });
            fetchVarieties();
            setMessage('Variety added successfully');
            setTimeout(() => setMessage(''), 3000);
        } catch (error) {
            setError('Failed to add variety');
        }
    };

    if (!isLoggedIn) {
        return (
            <div style={styles.loginContainer}>
                <h2 style={styles.heading}>Admin Login</h2>
                {error && <div style={styles.errorMessage}>{error}</div>}
                <form onSubmit={handleLogin} style={styles.form}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={loginData.username}
                        onChange={(e) => setLoginData({...loginData, username: e.target.value})}
                        style={styles.input}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={loginData.password}
                        onChange={(e) => setLoginData({...loginData, password: e.target.value})}
                        style={styles.input}
                    />
                    <button type="submit" style={styles.button}>Login</button>
                </form>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Admin Panel</h1>
            
            <div style={styles.navigation}>
                <button 
                    onClick={() => setActiveSection('varieties')}
                    style={{
                        ...styles.navButton,
                        ...(activeSection === 'varieties' ? styles.activeNavButton : {})
                    }}
                >
                    Manage Varieties
                </button>
                <button 
                    onClick={() => setActiveSection('users')}
                    style={{
                        ...styles.navButton,
                        ...(activeSection === 'users' ? styles.activeNavButton : {})
                    }}
                >
                    Manage Users
                </button>
            </div>

            {activeSection === 'varieties' ? (
                <div style={styles.adminPanel}>
                    {message && <div style={styles.successMessage}>{message}</div>}
                    {error && <div style={styles.errorMessage}>{error}</div>}

                    <section style={styles.section}>
                        <h2 style={styles.heading}>Add New Variety</h2>
                        <form onSubmit={handleAddVariety} style={styles.form}>
                            <input
                                type="text"
                                placeholder="Variety Name"
                                value={newVariety.name}
                                onChange={(e) => setNewVariety({...newVariety, name: e.target.value})}
                                style={styles.input}
                                required
                            />
                            <textarea
                                placeholder="Description"
                                value={newVariety.description}
                                onChange={(e) => setNewVariety({...newVariety, description: e.target.value})}
                                style={styles.textarea}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Origin"
                                value={newVariety.origin}
                                onChange={(e) => setNewVariety({...newVariety, origin: e.target.value})}
                                style={styles.input}
                                required
                            />
                            <button type="submit" style={styles.button}>Add Variety</button>
                        </form>
                    </section>

                    <section style={styles.section}>
                        <h2 style={styles.heading}>Existing Varieties</h2>
                        <div style={styles.tableContainer}>
                            <table style={styles.table}>
                                <thead>
                                    <tr>
                                        <th style={styles.tableHeader}>Name</th>
                                        <th style={styles.tableHeader}>Description</th>
                                        <th style={styles.tableHeader}>Origin</th>
                                        <th style={styles.tableHeader}>Harvest Times</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {varieties.map(variety => (
                                        <tr key={variety.id} style={styles.tableRow}>
                                            <td style={styles.tableCell}>
                                                <Link to={`/variety/${variety.id}`} style={styles.varietyLink}>
                                                    {variety.name}
                                                </Link>
                                            </td>
                                            <td style={styles.tableCell}>{variety.description}</td>
                                            <td style={styles.tableCell}>{variety.origin}</td>
                                            <td style={styles.tableCell}>
                                                {variety.harvestTimes && variety.harvestTimes.length > 0 ? (
                                                    Object.entries(
                                                        variety.harvestTimes.reduce((acc, ht) => {
                                                            if (!acc[ht.region]) {
                                                                acc[ht.region] = {
                                                                    months: [],
                                                                    is_peak_season: ht.is_peak_season
                                                                };
                                                            }
                                                            acc[ht.region].months.push(ht.month);
                                                            return acc;
                                                        }, {})
                                                    ).map(([region, data]) => (
                                                        <div key={region} style={styles.harvestTimeEntry}>
                                                            <strong>{region}:</strong>
                                                            <div style={styles.monthsList}>
                                                                {data.months
                                                                    .sort((a, b) => a - b)
                                                                    .map(m => new Date(0, m-1).toLocaleString('default', { month: 'short' }))
                                                                    .join(', ')}
                                                                {data.is_peak_season && (
                                                                    <span style={styles.peakSeasonTag}>Peak</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span style={styles.noHarvestTimes}>No harvest times</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            ) : (
                <UserManagement />
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    heading: {
        color: '#2e7d32',
        textAlign: 'center',
        marginBottom: '30px',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    navButton: {
        backgroundColor: '#f5f5f5',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    activeNavButton: {
        backgroundColor: '#2e7d32',
        color: 'white',
    },
    adminPanel: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    section: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        marginBottom: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    input: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ddd',
    },
    textarea: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ddd',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#2e7d32',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1b5e20',
        },
    },
    tableContainer: {
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: 'white',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
    tableHeader: {
        padding: '12px 15px',
        textAlign: 'left',
        backgroundColor: '#f5f5f5',
        borderBottom: '2px solid #ddd',
        color: '#2e7d32',
        fontWeight: 'bold',
    },
    tableRow: {
        borderBottom: '1px solid #eee',
        '&:hover': {
            backgroundColor: '#f9f9f9',
        },
    },
    tableCell: {
        padding: '12px 15px',
        verticalAlign: 'top',
    },
    harvestTimeEntry: {
        marginBottom: '8px',
        padding: '4px 8px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        fontSize: '0.9em',
    },
    monthsList: {
        fontSize: '0.85em',
        color: '#666',
    },
    noHarvestTimes: {
        color: '#999',
        fontStyle: 'italic',
        fontSize: '0.9em',
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center',
        marginBottom: '15px',
    },
    successMessage: {
        color: '#2e7d32',
        textAlign: 'center',
        marginBottom: '15px',
    },
    varietyLink: {
        color: '#2e7d32',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    loginContainer: {
        maxWidth: '300px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
};

export default AdminPanel; 