import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, logout } = useAuth();
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav style={styles.nav}>
            <div style={styles.navContent}>
                <Link to="/" style={styles.logo} onClick={closeMenu}>
                    🥑 AvoCalendar
                </Link>

                <button 
                    onClick={toggleMenu} 
                    style={styles.menuButton}
                    aria-label="Toggle menu"
                >
                    <div style={styles.hamburger}>
                        <span style={{
                            ...styles.hamburgerLine,
                            transform: isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'none'
                        }}/>
                        <span style={{
                            ...styles.hamburgerLine,
                            opacity: isOpen ? 0 : 1
                        }}/>
                        <span style={{
                            ...styles.hamburgerLine,
                            transform: isOpen ? 'rotate(-45deg) translate(5px, -5px)' : 'none'
                        }}/>
                    </div>
                </button>

                <div style={{
                    ...styles.menuItems,
                    ...(isOpen ? styles.menuOpen : styles.menuClosed)
                }}>
                    <Link 
                        to="/" 
                        style={{
                            ...styles.navLink,
                            ...(location.pathname === '/' ? styles.activeLink : {})
                        }}
                        onClick={closeMenu}
                    >
                        Calendar
                    </Link>
                    <Link 
                        to="/varieties" 
                        style={{
                            ...styles.navLink,
                            ...(location.pathname === '/varieties' ? styles.activeLink : {})
                        }}
                        onClick={closeMenu}
                    >
                        Varieties
                    </Link>
                    
                    {/* User navigation items */}
                    {user ? (
                        <>
                            {Boolean(user.isAdmin) && (
                                <Link 
                                    to="/admin" 
                                    style={{
                                        ...styles.navLink,
                                        ...(location.pathname === '/admin' ? styles.activeLink : {})
                                    }}
                                    onClick={closeMenu}
                                >
                                    Admin
                                </Link>
                            )}
                            <Link 
                                to="/profile" 
                                style={{
                                    ...styles.navLink,
                                    ...(location.pathname === '/profile' ? styles.activeLink : {})
                                }}
                                onClick={closeMenu}
                            >
                                Profile
                            </Link>
                            <button
                                onClick={() => {
                                    closeMenu();
                                    logout();
                                }}
                                style={styles.logoutButton}
                            >
                                Logout
                            </button>
                        </>
                    ) : (
                        <>
                            <Link 
                                to="/login" 
                                style={{
                                    ...styles.navLink,
                                    ...(location.pathname === '/login' ? styles.activeLink : {})
                                }}
                                onClick={closeMenu}
                            >
                                Login
                            </Link>
                            <Link 
                                to="/register" 
                                style={{
                                    ...styles.navLink,
                                    ...(location.pathname === '/register' ? styles.activeLink : {})
                                }}
                                onClick={closeMenu}
                            >
                                Register
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
};

const styles = {
    nav: {
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
    },
    navContent: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 20px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
    },
    logo: {
        color: '#2e7d32',
        textDecoration: 'none',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        zIndex: 1001,
    },
    menuButton: {
        display: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '10px',
        zIndex: 1001,
        '@media (max-width: 768px)': {
            display: 'block',
        },
    },
    hamburger: {
        width: '24px',
        height: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    hamburgerLine: {
        width: '24px',
        height: '2px',
        backgroundColor: '#2e7d32',
        transition: 'all 0.3s ease',
    },
    menuItems: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        '@media (max-width: 768px)': {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ffffff',
            flexDirection: 'column',
            padding: '80px 20px 20px',
            transition: 'transform 0.3s ease-in-out',
        },
    },
    menuOpen: {
        '@media (max-width: 768px)': {
            transform: 'translateX(0)',
        },
    },
    menuClosed: {
        '@media (max-width: 768px)': {
            transform: 'translateX(100%)',
        },
    },
    navLink: {
        color: '#2e7d32',
        textDecoration: 'none',
        padding: '8px 12px',
        borderRadius: '4px',
        transition: 'background-color 0.2s',
        '@media (max-width: 768px)': {
            fontSize: '1.2rem',
            padding: '12px',
            width: '100%',
            textAlign: 'center',
        },
    },
    activeLink: {
        backgroundColor: '#e8f5e9',
        fontWeight: 'bold',
    },
    logoutButton: {
        background: 'none',
        border: 'none',
        color: '#d32f2f',
        cursor: 'pointer',
        padding: '8px 12px',
        fontSize: '1em',
        '@media (max-width: 768px)': {
            fontSize: '1.2rem',
            padding: '12px',
            width: '100%',
        },
    },
};

export default Navigation; 