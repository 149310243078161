import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import config from '../config';

const { API_BASE_URL } = config;

const AvocadoVarietyDetails = () => {
    const { user } = useAuth();
    const [variety, setVariety] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedVariety, setEditedVariety] = useState(null);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
    
    const [showAddHarvestTime, setShowAddHarvestTime] = useState(false);
    const [newHarvestTime, setNewHarvestTime] = useState({
        region: '',
        months: new Array(12).fill(false),
        is_peak_season: false
    });

    useEffect(() => {
        fetchVarietyDetails();
    }, [id]);

    const fetchVarietyDetails = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/varieties/${id}`);
            if (!response.ok) throw new Error('Failed to fetch variety details');
            const data = await response.json();
            setVariety(data);
            setEditedVariety(data);
        } catch (error) {
            setError('Failed to load variety details');
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/admin/varieties/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                },
                body: JSON.stringify(editedVariety)
            });
            
            if (!response.ok) throw new Error('Failed to update variety');
            
            setVariety(editedVariety);
            setIsEditing(false);
            setMessage('Variety updated successfully');
            setTimeout(() => setMessage(''), 3000);
        } catch (error) {
            setError('Failed to update variety');
        }
    };

    const handleAddHarvestTime = async (e) => {
        e.preventDefault();
        try {
            const selectedMonths = newHarvestTime.months
                .map((isSelected, index) => isSelected ? index + 1 : null)
                .filter(month => month !== null);

            await Promise.all(selectedMonths.map(month =>
                fetch(`${API_BASE_URL}/admin/harvest-times`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user?.token}`
                    },
                    body: JSON.stringify({
                        variety_id: id,
                        month,
                        region: newHarvestTime.region,
                        is_peak_season: newHarvestTime.is_peak_season
                    })
                })
            ));

            setNewHarvestTime({
                region: '',
                months: new Array(12).fill(false),
                is_peak_season: false
            });
            setShowAddHarvestTime(false);
            fetchVarietyDetails();
            setMessage('Harvest times added successfully');
        } catch (error) {
            setError('Failed to add harvest times');
        }
    };

    const handleDeleteHarvestTime = async (region) => {
        if (window.confirm(`Are you sure you want to delete harvest times for ${region}?`)) {
            try {
                await fetch(`${API_BASE_URL}/admin/harvest-times/${id}/${region}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${user?.token}`
                    }
                });
                fetchVarietyDetails();
                setMessage('Harvest times deleted successfully');
            } catch (error) {
                setError('Failed to delete harvest times');
            }
        }
    };

    const toggleAllMonths = () => {
        const areAllSelected = newHarvestTime.months.every(month => month);
        setNewHarvestTime({
            ...newHarvestTime,
            months: new Array(12).fill(!areAllSelected)
        });
    };

    if (!variety) return <div>Loading...</div>;

    return (
        <div style={styles.container}>
            <Link to="/" style={styles.backLink}>← Back to Calendar</Link>
            
            {message && <div style={styles.successMessage}>{message}</div>}
            {error && <div style={styles.errorMessage}>{error}</div>}

            <div style={styles.details}>
                {isEditing ? (
                    <form onSubmit={handleSave} style={styles.editForm}>
                        <h2 style={styles.heading}>Edit {variety.name}</h2>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Name:</label>
                            <input
                                type="text"
                                value={editedVariety.name}
                                onChange={(e) => setEditedVariety({...editedVariety, name: e.target.value})}
                                style={styles.input}
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Description:</label>
                            <textarea
                                value={editedVariety.description}
                                onChange={(e) => setEditedVariety({...editedVariety, description: e.target.value})}
                                style={styles.textarea}
                                rows={4}
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Origin:</label>
                            <input
                                type="text"
                                value={editedVariety.origin}
                                onChange={(e) => setEditedVariety({...editedVariety, origin: e.target.value})}
                                style={styles.input}
                            />
                        </div>
                        <div style={styles.buttonGroup}>
                            <button type="submit" style={styles.saveButton}>
                                Save Changes
                            </button>
                            <button 
                                type="button" 
                                onClick={() => setIsEditing(false)}
                                style={styles.cancelButton}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                ) : (
                    <>
                        <div style={styles.header}>
                            <h1 style={styles.heading}>{variety.name}</h1>
                            {user && user.isAdmin && (
                                <button 
                                    onClick={() => setIsEditing(true)}
                                    style={styles.editButton}
                                >
                                    Edit Details
                                </button>
                            )}
                        </div>

                        <div style={styles.infoCard}>
                            <h2 style={styles.subheading}>About this Variety</h2>
                            <p style={styles.description}>{variety.description}</p>
                            <p style={styles.origin}>Origin: {variety.origin}</p>
                        </div>
                    </>
                )}

                <div style={styles.harvestTimes}>
                    <div style={styles.harvestHeader}>
                        <h2 style={styles.subheading}>Harvest Times</h2>
                        {user && user.isAdmin && (
                            <button 
                                onClick={() => setShowAddHarvestTime(!showAddHarvestTime)}
                                style={styles.addButton}
                            >
                                {showAddHarvestTime ? 'Cancel' : 'Add Harvest Time'}
                            </button>
                        )}
                    </div>

                    {showAddHarvestTime && (
                        <form onSubmit={handleAddHarvestTime} style={styles.addHarvestForm}>
                            <input
                                type="text"
                                placeholder="Region (e.g., Southern California)"
                                value={newHarvestTime.region}
                                onChange={(e) => setNewHarvestTime({
                                    ...newHarvestTime,
                                    region: e.target.value
                                })}
                                style={styles.input}
                                required
                            />
                            
                            <div style={styles.monthsSection}>
                                <div style={styles.monthsHeader}>
                                    <h3 style={styles.monthsTitle}>Select Months</h3>
                                    <button
                                        type="button"
                                        onClick={toggleAllMonths}
                                        style={styles.toggleButton}
                                    >
                                        Toggle All Months
                                    </button>
                                </div>
                                <div style={styles.monthsGrid}>
                                    {[...Array(12)].map((_, i) => (
                                        <label key={i} style={styles.monthLabel}>
                                            <input
                                                type="checkbox"
                                                checked={newHarvestTime.months[i]}
                                                onChange={(e) => {
                                                    const newMonths = [...newHarvestTime.months];
                                                    newMonths[i] = e.target.checked;
                                                    setNewHarvestTime({
                                                        ...newHarvestTime,
                                                        months: newMonths
                                                    });
                                                }}
                                                style={styles.checkbox}
                                            />
                                            {new Date(0, i).toLocaleString('default', { month: 'short' })}
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <label style={styles.peakSeasonLabel}>
                                <input
                                    type="checkbox"
                                    checked={newHarvestTime.is_peak_season}
                                    onChange={(e) => setNewHarvestTime({
                                        ...newHarvestTime,
                                        is_peak_season: e.target.checked
                                    })}
                                    style={styles.checkbox}
                                />
                                Peak Season
                            </label>

                            <button type="submit" style={styles.submitButton}>
                                Add Harvest Time
                            </button>
                        </form>
                    )}

                    <div style={styles.harvestTimesList}>
                        {variety.harvestTimes && variety.harvestTimes.length > 0 ? (
                            Object.entries(
                                variety.harvestTimes.reduce((acc, ht) => {
                                    if (!acc[ht.region]) {
                                        acc[ht.region] = {
                                            months: [],
                                            is_peak_season: ht.is_peak_season
                                        };
                                    }
                                    acc[ht.region].months.push(ht.month);
                                    return acc;
                                }, {})
                            ).map(([region, data]) => (
                                <div key={region} style={styles.regionCard}>
                                    <div style={styles.regionHeader}>
                                        <h3 style={styles.regionName}>{region}</h3>
                                        {user && user.isAdmin && (
                                            <button
                                                onClick={() => handleDeleteHarvestTime(region)}
                                                style={styles.deleteButton}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                    <p style={styles.months}>
                                        {data.months
                                            .sort((a, b) => a - b)
                                            .map(m => new Date(0, m-1).toLocaleString('default', { month: 'long' }))
                                            .join(', ')}
                                    </p>
                                    {data.is_peak_season && (
                                        <span style={styles.peakSeason}>Peak Season</span>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p style={styles.noHarvestTimes}>No harvest times available yet</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '1000px',
        margin: '0 auto',
        padding: '20px',
    },
    backLink: {
        display: 'inline-block',
        marginBottom: '20px',
        color: '#2e7d32',
        textDecoration: 'none',
        fontSize: '16px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    heading: {
        color: '#2e7d32',
        margin: '0',
        fontSize: '2.5em',
    },
    infoCard: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        marginBottom: '20px',
    },
    description: {
        fontSize: '1.1em',
        lineHeight: '1.6',
        color: '#333',
    },
    origin: {
        fontSize: '1.1em',
        color: '#666',
    },
    harvestTimes: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    harvestHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    addButton: {
        padding: '8px 16px',
        backgroundColor: '#2e7d32',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    addHarvestForm: {
        // ... (addHarvestForm styles)
    },
    monthsSection: {
        // ... (monthsSection styles)
    },
    monthsHeader: {
        // ... (monthsHeader styles)
    },
    monthsTitle: {
        // ... (monthsTitle styles)
    },
    toggleButton: {
        // ... (toggleButton styles)
    },
    monthsGrid: {
        // ... (monthsGrid styles)
    },
    monthLabel: {
        // ... (monthLabel styles)
    },
    checkbox: {
        // ... (checkbox styles)
    },
    peakSeasonLabel: {
        // ... (peakSeasonLabel styles)
    },
    submitButton: {
        // ... (submitButton styles)
    },
    regionCard: {
        // ... (regionCard styles)
    },
    regionHeader: {
        // ... (regionHeader styles)
    },
    regionName: {
        // ... (regionName styles)
    },
    deleteButton: {
        // ... (deleteButton styles)
    },
    months: {
        // ... (months styles)
    },
    noHarvestTimes: {
        // ... (noHarvestTimes styles)
    },
    editButton: {
        // ... (editButton styles)
    },
    editForm: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        marginBottom: '20px',
    },
    formGroup: {
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '8px',
        color: '#2e7d32',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px 12px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '16px',
    },
    textarea: {
        width: '100%',
        padding: '8px 12px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '16px',
        resize: 'vertical',
    },
    buttonGroup: {
        display: 'flex',
        gap: '10px',
        marginTop: '20px',
    },
    saveButton: {
        padding: '8px 16px',
        backgroundColor: '#2e7d32',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    cancelButton: {
        padding: '8px 16px',
        backgroundColor: '#d32f2f',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    successMessage: {
        padding: '10px',
        backgroundColor: '#c8e6c9',
        color: '#2e7d32',
        borderRadius: '4px',
        marginBottom: '20px',
    },
    errorMessage: {
        padding: '10px',
        backgroundColor: '#ffcdd2',
        color: '#c62828',
        borderRadius: '4px',
        marginBottom: '20px',
    },
};

export default AvocadoVarietyDetails; 