import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import config from '../config';

const { API_BASE_URL } = config;  // Replace hardcoded URL

const UserManagement = () => {
    const { user } = useAuth();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editingUser, setEditingUser] = useState(null);
    const [showAddUser, setShowAddUser] = useState(false);
    const [newUser, setNewUser] = useState({
        username: '',
        email: '',
        password: '',
        is_admin: false
    });

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/admin/users`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch users');
            const data = await response.json();
            setUsers(data);
            setLoading(false);
        } catch (error) {
            setError('Failed to load users');
            setLoading(false);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/admin/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify(newUser)
            });
            if (!response.ok) throw new Error('Failed to add user');
            await fetchUsers();
            setShowAddUser(false);
            setNewUser({ username: '', email: '', password: '', is_admin: false });
        } catch (error) {
            setError('Failed to add user');
        }
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/admin/users/${editingUser.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify(editingUser)
            });
            if (!response.ok) throw new Error('Failed to update user');
            await fetchUsers();
            setEditingUser(null);
        } catch (error) {
            setError('Failed to update user');
        }
    };

    const handleDeleteUser = async (userId) => {
        if (!window.confirm('Are you sure you want to delete this user?')) return;
        try {
            const response = await fetch(`${API_BASE_URL}/admin/users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            if (!response.ok) throw new Error('Failed to delete user');
            await fetchUsers();
        } catch (error) {
            setError('Failed to delete user');
        }
    };

    if (loading) return <div style={styles.loading}>Loading...</div>;
    if (error) return <div style={styles.error}>{error}</div>;

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h2 style={styles.title}>User Management</h2>
                <button 
                    onClick={() => setShowAddUser(true)}
                    style={styles.addButton}
                >
                    Add New User
                </button>
            </div>

            {showAddUser && (
                <div style={styles.formContainer}>
                    <h3>Add New User</h3>
                    <form onSubmit={handleAddUser} style={styles.form}>
                        <input
                            type="text"
                            placeholder="Username"
                            value={newUser.username}
                            onChange={(e) => setNewUser({...newUser, username: e.target.value})}
                            style={styles.input}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={newUser.email}
                            onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                            style={styles.input}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={newUser.password}
                            onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                            style={styles.input}
                            required
                        />
                        <label style={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                checked={newUser.is_admin}
                                onChange={(e) => setNewUser({...newUser, is_admin: e.target.checked})}
                            />
                            Admin User
                        </label>
                        <div style={styles.buttonGroup}>
                            <button type="submit" style={styles.submitButton}>Add User</button>
                            <button 
                                type="button" 
                                onClick={() => setShowAddUser(false)}
                                style={styles.cancelButton}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}

            <div style={styles.userList}>
                {users.map(user => (
                    <div key={user.id} style={styles.userCard}>
                        {editingUser?.id === user.id ? (
                            <form onSubmit={handleUpdateUser} style={styles.form}>
                                <input
                                    type="text"
                                    value={editingUser.username}
                                    onChange={(e) => setEditingUser({
                                        ...editingUser,
                                        username: e.target.value
                                    })}
                                    style={styles.input}
                                />
                                <input
                                    type="email"
                                    value={editingUser.email}
                                    onChange={(e) => setEditingUser({
                                        ...editingUser,
                                        email: e.target.value
                                    })}
                                    style={styles.input}
                                />
                                <label style={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        checked={editingUser.is_admin}
                                        onChange={(e) => setEditingUser({
                                            ...editingUser,
                                            is_admin: e.target.checked
                                        })}
                                    />
                                    Admin User
                                </label>
                                <div style={styles.buttonGroup}>
                                    <button type="submit" style={styles.submitButton}>
                                        Save
                                    </button>
                                    <button 
                                        type="button"
                                        onClick={() => setEditingUser(null)}
                                        style={styles.cancelButton}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div style={styles.userInfo}>
                                    <h3 style={styles.username}>{user.username}</h3>
                                    <p style={styles.email}>{user.email}</p>
                                    {user.is_admin && (
                                        <span style={styles.adminBadge}>Admin</span>
                                    )}
                                </div>
                                <div style={styles.actions}>
                                    <button 
                                        onClick={() => setEditingUser(user)}
                                        style={styles.editButton}
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        onClick={() => handleDeleteUser(user.id)}
                                        style={styles.deleteButton}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '30px',
    },
    title: {
        color: '#2e7d32',
        margin: 0,
    },
    addButton: {
        backgroundColor: '#2e7d32',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    userList: {
        display: 'grid',
        gap: '20px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    },
    userCard: {
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    userInfo: {
        marginBottom: '15px',
    },
    username: {
        margin: '0 0 5px 0',
        color: '#2e7d32',
    },
    email: {
        margin: '0',
        color: '#666',
    },
    adminBadge: {
        backgroundColor: '#e8f5e9',
        color: '#2e7d32',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '0.8rem',
        marginTop: '5px',
        display: 'inline-block',
    },
    actions: {
        display: 'flex',
        gap: '10px',
    },
    editButton: {
        backgroundColor: '#f5f5f5',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    deleteButton: {
        backgroundColor: '#ffebee',
        color: '#c62828',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    input: {
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '1rem',
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    buttonGroup: {
        display: 'flex',
        gap: '10px',
    },
    submitButton: {
        backgroundColor: '#2e7d32',
        color: 'white',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    cancelButton: {
        backgroundColor: '#f5f5f5',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    loading: {
        textAlign: 'center',
        padding: '50px',
        fontSize: '1.2em',
        color: '#666',
    },
    error: {
        textAlign: 'center',
        padding: '50px',
        color: '#c62828',
    },
};

export default UserManagement; 